/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import { InputType } from '../types.d';
import theme from '../theme';

const MDEditor = React.lazy(() => import('@uiw/react-md-editor'));

interface InputProps {
  handleChange: (value: string | number) => void;
  name: string;
  options?: string[];
  type?: InputType;
  value?: string | number;
};

const Input: React.FC<InputProps> = ({
  handleChange,
  name,
  options,
  type = 'string',
  value,
}) => {
  let component;
  switch (type) {
    case 'longtext':
      component = (
        <React.Suspense fallback={(
          <textarea
            onChange={(e) => handleChange(e.target.value ?? '')}
            value={value?.toString() ?? ''} />
        )}>
          <MDEditor
            onChange={(val) => handleChange(val ?? '')}
            value={value?.toString() ?? ''}
          />
        </React.Suspense>
      );
      break;
    case 'select':
      component = (
        <select
          css={css`
            appearance: none;
            background-color: ${theme.colors.offBlack};
            background-image:
              linear-gradient(45deg, transparent 50%, gray 50%),
              linear-gradient(135deg, gray 50%, transparent 50%),
              linear-gradient(to right, ${theme.colors.gray.base}, ${theme.colors.gray.base});
            background-position:
              calc(100% - 12px) calc(1em + 3px),
              calc(100% - 7px) calc(1em + 3px),
              calc(100% - 2.2em) 0;
            background-size:
              5px 5px,
              5px 5px,
              1px 3em;
            background-repeat: no-repeat;
            border: 1px solid ${theme.colors.gray.base};
            border-radius: ${theme.spacing.base};
            color: ${theme.colors.white};
            font-size: 11px;
            line-height: ${theme.spacing.x4};
            outline: none;
            padding: ${theme.spacing.x2};

            &:focus,
            &:hover {
              border-color: ${theme.colors.white};
              background-image:
                linear-gradient(45deg, transparent 50%, gray 50%),
                linear-gradient(135deg, gray 50%, transparent 50%),
                linear-gradient(to right, ${theme.colors.white}, ${theme.colors.white});
            }
          `}
          name={name}
          onChange={(e) => handleChange(e.target.value)}
          value={value}
        >
          {options?.map((option) => (
            <option key={option} value={option}>{option}</option>
          ))}
        </select>
      );
      break;
    default:
      component = (
        <input
          css={css`
            appearance: none;
            background-color: ${theme.colors.offBlack};
            border: 1px solid ${theme.colors.gray.base};
            border-radius: ${theme.spacing.base};
            font-size: 11px;
            line-height: ${theme.spacing.x4};
            outline: none;
            padding: ${theme.spacing.x2};

            &:focus,
            &:hover {
              border-color: ${theme.colors.white};
            }
          `}
          name={name}
          onChange={(e) => handleChange(e.target.value)}
          type={type}
          value={value}
        />
      );
  }

  return component;
};

export default Input;