/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import {
  Ancestor,
  FormType,
  NewAncestor,
} from '../types.d';
import { Button } from '../styled';
import theme from '../theme';

import DataContext from '../context/DataContext';

import AncestorPicker from './AncestorPicker';
import PropertiesPicker from './PropertiesPicker';

interface AncestorFormProps {
  ancestor?: Ancestor;
  children?: number[];
  onSubmit?: () => void;
  parents?: number[];
  type?: FormType;
}

const ancestorPickerWrapperStyles = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: ${theme.spacing.base};
`;

const AncestorForm: React.FC<AncestorFormProps> = ({
  ancestor,
  children,
  onSubmit,
  parents,
  type = FormType.NEW_ANCESTOR,
}) => {
  const {id: inputAncestorId, ...inputAncestor} = ancestor ?? {};
  const [newAncestor, setNewAncestor] = React.useState<NewAncestor>(inputAncestor);
  const [childIds, setChildIds] = React.useState<number[]>(children ?? []);
  const [parentIds, setParentIds] = React.useState<number[]>(parents ?? []);

  const {
    addNewAncestor,
    allAncestors,
    updateAncestor,
  } = React.useContext(DataContext);

  return (
    <div>
      <h2>{type}</h2>
      <PropertiesPicker
        handleChange={(newAncestor) => setNewAncestor(newAncestor)}
        newAncestor={newAncestor}
      />
      <h3>Parents</h3>
      {
        parentIds.map((parentId, index) => (
          <div
            css={[
              ancestorPickerWrapperStyles,
              css`padding-left: 59px;`,
            ]}
            key={`parent-${index}`}
          >
            <AncestorPicker
              allAncestors={allAncestors}
              handleChange={(id) => setParentIds(
                [...parentIds.slice(0, index), ...(id !== -1 ? [id] : []), ...parentIds.slice(index+1)]
              )}
              name={`${parentId}-parent`}
              value={parentIds[index] ?? -1} 
            />
            <Button
              onClick={() => setParentIds(
                [...parentIds.slice(0, index), ...parentIds.slice(index+1)]
              )}
            >
              remove
            </Button>
          </div>
        ))
      }
      {
        parentIds.length <= 1 ? (
          <div
            css={ancestorPickerWrapperStyles}
          >
            <AncestorPicker
              allAncestors={allAncestors}
              handleChange={(id) => setParentIds(Array.from(new Set([...parentIds, id])))}
              name="new-parent"
              value={-1} 
            />
          </div>
        ) : null
      }
      <h3>Children</h3>
      {
        childIds.map((childId, index) => (
          <div
            css={[
              ancestorPickerWrapperStyles,
              css`padding-left: 59px;`,
            ]}
            key={`child-${index}`}
          >
            <AncestorPicker
              allAncestors={allAncestors}
              handleChange={(id) => setChildIds(
                [...childIds.slice(0, index), ...(id !== -1 ? [id] : []), ...childIds.slice(index+1)]
              )}
              name={`${childId}-child`}
              value={childIds[index] ?? -1} 
            />
            <Button
              onClick={() => setChildIds(
                [...childIds.slice(0, index), ...childIds.slice(index+1)]
              )}
            >
              remove
            </Button>
          </div>
        ))
      }
      {
        <div
          css={ancestorPickerWrapperStyles}
        >
          <AncestorPicker
            allAncestors={allAncestors}
            handleChange={(id) => setChildIds(Array.from(new Set([...childIds, id])))}
            name="new-child"
            value={-1} 
          />
        </div>
      }
      <div css={css`
        column-gap: ${theme.spacing.x2};
        display: flex;
        justify-content: center;
        padding: ${theme.spacing.base};
      `}>
        {
          onSubmit && (
            <Button
              textOnly={false}
              onClick={() => onSubmit()}
            >
              Cancel
            </Button>
          )
        }
        <Button
          textOnly={false}
          onClick={() => {
            if (type === FormType.NEW_ANCESTOR) {
              addNewAncestor(newAncestor, childIds, parentIds);
            } else if (typeof inputAncestorId !== 'undefined') {
              updateAncestor(newAncestor, inputAncestorId, childIds, parentIds);
            }
            onSubmit && onSubmit();
          }}
          type='submit'
        >
          Submit
        </Button>
      </div>
    </div>
  )
};

export default AncestorForm;