import React from 'react';
import { Neo4jContext } from 'use-neo4j';

import {
  Ancestor,
  NewAncestor,
  QueriedRelationship,
  Relationship
} from '../types.d';
import { getRelationshipType, toLetter } from '../utils';

const useData = () => {
  const { driver } = React.useContext(Neo4jContext);

  const [allAncestors, setAllAncestors] = React.useState<Ancestor[]>([]);
  const [allRelationships, setAllRelationships] = React.useState<Relationship[]>([]);
  const [toBeSelectedNodeId, setToBeSelectedNodeId] = React.useState<number | undefined>();

  const getAncestorById = React.useCallback(
    (id: string | number | undefined) => (
      allAncestors.find((a) => a.id === id)
    ), [
    allAncestors,
  ]);

  const graphData = React.useMemo(() => ({
    nodes: allAncestors.map((ancestor) => ({
      id: ancestor.id,
      label: ancestor.name,
    })),
    links: allRelationships.map((relationship) => ({
      source: relationship.child_id,
      target: relationship.parent_id,
      value: 1,
    })),
  }), [
    allAncestors,
    allRelationships,
  ]);

  const fetchAllAncestors = React.useCallback(async () => {
    return new Promise((resolve, reject) => {
      const ancestors: Ancestor[] = [];
      driver
        ?.session()
        .run('MATCH (a:Ancestor) RETURN a;')
        .subscribe({
          onCompleted: () => {
            setAllAncestors(ancestors);
            resolve(ancestors);
          },
          onError: (err) => {
            console.log('error fetching ancestors: ' + err);
          },
          onNext: (record) => {
            const ancestor = record.get(0);
            Object.entries(ancestor.properties).forEach(
              ([key, val]) => {
                if (typeof val !== 'string') {
                  ancestor.properties[key] = (val as { low: number, high: number }).low
                }
              }
            );
            ancestors.push({
              id: ancestor.identity.low,
              ...ancestor.properties,
            });
          },
        });
    });
  }, [
    driver,
  ]);

  const fetchAllRelationships = React.useCallback(async () => {
    return new Promise((resolve, reject) => {
      const relationships: Relationship[] = [];
      driver
        ?.session()
        .run('MATCH (:Ancestor)-[r:IS_CHILD_OF]->(:Ancestor) return r;')
        .subscribe({
          onCompleted: () => {
            setAllRelationships(relationships);
            resolve(relationships);
          },
          onError: (err) => {
            console.log('error fetching relationships: ' + err);
          },
          onNext: (record) => {
            const relationship = record.get(0);
            relationships.push({
              child_id: relationship.start.low,
              parent_id: relationship.end.low,
            });
          }
        });
    });
  }, [
    driver,
  ]);

  const addNewAncestor = React.useCallback(
    (ancestor: NewAncestor, child_ids: number[] = [], parent_ids: number[] = []) => {
      const relationshipNodeIds = [...child_ids, ...parent_ids];
      const query = `CREATE (a:Ancestor ${JSON.stringify(ancestor).replace(/"([^"]+)":/g, '$1:')})
${relationshipNodeIds.map((nodeId, i) => `WITH a MATCH (${toLetter(i)}:Ancestor) WHERE ID(${toLetter(i)}) = ${nodeId}
CREATE (${parent_ids.includes(nodeId) ? 'a' : toLetter(i)})-[:IS_CHILD_OF]->(${parent_ids.includes(nodeId) ? toLetter(i) : 'a'})
`).join('')}
RETURN a;`;
      driver
        ?.session()
        .run(query)
        .then((res) => {
          const ancestorId = res?.records[0].get(0).identity.low;
          fetchAllAncestors().then(() => {
            fetchAllRelationships().then(() => {
              setToBeSelectedNodeId(ancestorId);
            });
          });
        })
        .catch((err) => {
          console.log(`error adding new ancestor '${ancestor.name}': ${err}`);
        });
    }, [
    driver,
    fetchAllAncestors,
    fetchAllRelationships,
  ]
  );

  const updateAncestor = React.useCallback(
    (ancestor: NewAncestor, id: number, child_ids: number[] = [], parent_ids: number[] = []) => {
      const relationshipNodeIds = [...child_ids, ...parent_ids];
      const query = `MATCH (a:Ancestor)-[r:IS_CHILD_OF]-(Ancestor) WHERE ID(a) = ${id} DELETE r;`;
      driver
        ?.session()
        .run(query)
        .then(() => {
          driver
            ?.session()
            .run(`MATCH (a:Ancestor) WHERE ID(a) = ${id}
SET a = ${JSON.stringify(ancestor).replace(/"([^"]+)":/g, '$1:')}
${relationshipNodeIds.map((nodeId, i) => `WITH a MATCH (${toLetter(i)}:Ancestor) WHERE ID(${toLetter(i)}) = ${nodeId}
CREATE (${parent_ids.includes(nodeId) ? 'a' : toLetter(i)})-[:IS_CHILD_OF]->(${parent_ids.includes(nodeId) ? toLetter(i) : 'a'})
`).join('')};`)
            .then(() => {
              fetchAllAncestors().then(() => {
                fetchAllRelationships().then(() => {
                  setToBeSelectedNodeId(id);
                });
              });
            })
            .catch((err) => {
              console.log(`error updating ancestor '${ancestor.name}': ${err}`);
            });
        })
        .catch((err) => {
          console.log(`error updating ancestor '${ancestor.name}': ${err}`);
        });
    }, [
    driver,
    fetchAllAncestors,
    fetchAllRelationships,
  ]);

  const getChildrenFromRelationships = React.useCallback(
    (relationships: Relationship[]) => (
      relationships.map((rel) => getAncestorById(rel.child_id))
    ), [
    getAncestorById,
  ]);

  const getParentsFromRelationships = React.useCallback(
    (relationships: Relationship[]) => (
      relationships.map((rel) => getAncestorById(rel.parent_id))
    ), [
    getAncestorById,
  ]);

  const getRelationshipsByChildId = React.useCallback(
    (id: string | number | undefined) => (
      allRelationships.filter((r) => r.child_id === id)
    ), [
    allRelationships,
  ]);

  const getRelationshipsByParentId = React.useCallback(
    (id: string | number | undefined) => (
      allRelationships.filter((r) => r.parent_id === id)
    ), [
    allRelationships,
  ]);

  const getChildrenByAncestorId = React.useCallback(
    (id: string | number | undefined) => (
      getChildrenFromRelationships(getRelationshipsByParentId(id))
    ), [
    getChildrenFromRelationships,
    getRelationshipsByParentId,
  ]);

  const getParentsByAncestorId = React.useCallback(
    (id: string | number | undefined) => (
      getParentsFromRelationships(getRelationshipsByChildId(id))
    ), [
    getParentsFromRelationships,
    getRelationshipsByChildId,
  ]);

  const refetchData = React.useCallback(() => {
    fetchAllAncestors().then(() => {
      fetchAllRelationships();
    });
    ;
  }, [
    fetchAllAncestors,
    fetchAllRelationships,
  ]);

  React.useEffect(() => {
    refetchData();
  }, [
    refetchData,
  ]);

  const queryRelationshipBetweenAncestors = React.useCallback((
    ancestorAId: number,
    ancestorBId: number,
  ) => {
    return new Promise<QueriedRelationship[]>((resolve, reject) => {
      driver
        ?.session()
        .run(
          `MATCH p=shortestPath((a:Ancestor)-[:IS_CHILD_OF*1..50]->(b:Ancestor)) WHERE (ID(a) = ${ancestorAId} AND ID(b) = ${ancestorBId}) OR (ID(a) = ${ancestorBId} AND ID(b) = ${ancestorAId}) RETURN p;`
        )
        .then((res) => {
          if (res && res.records.length >= 1) {
            const relationships: QueriedRelationship[] = [];
            for (const record of res.records) {
              const secondSegmentLength = (
                record.get(0).start.identity.low === ancestorAId
                  ? record.get(0).length
                  : 0
              );
              const firstSegmentLength = record.get(0).length - secondSegmentLength;

              relationships.push({
                relationshipNames: getRelationshipType(firstSegmentLength, secondSegmentLength),
              })
            }
            resolve(relationships);
          } else {
            driver
              ?.session()
              .run(
                `MATCH p=(a:Ancestor)-[:IS_CHILD_OF*1..50]->(c:Ancestor)<-[:IS_CHILD_OF*1..50]-(b:Ancestor) WHERE ID(a) = ${ancestorAId} AND ID(b) = ${ancestorBId} RETURN p, c ORDER BY length(p);`
              )
              .then((res) => {
                if (res && res.records.length >= 1) {
                  const relationships: QueriedRelationship[] = [];
                  for (const record of res.records) {
                    const commonAncestorId = record.get(1).identity.low;
                    const commonAncestorSegment = (
                      record.get(0)
                        .segments
                        .filter(
                          (s: { relationship: { end: { low: number; }; }; }) => s.relationship.end.low === commonAncestorId
                        )[1]
                    );
                    const totalRelationshipLength = record.get(0).length;
                    const secondSegmentLength = record.get(0).segments.indexOf(commonAncestorSegment);

                    relationships.push({
                      commonAncestorId,
                      relationshipNames: getRelationshipType(totalRelationshipLength - secondSegmentLength, secondSegmentLength),
                    })
                  }
                  resolve(relationships);
                } else {
                  resolve([]);
                }
              })
              .catch((err) => {
                console.log(`error querying relationship between ${ancestorAId} and ${ancestorBId}: ${err}`)
              });
          }
        })
        .catch((err) => {
          console.log(`error querying relationship between ${ancestorAId} and ${ancestorBId}: ${err}`)
        });
    });
  }, [
    driver,
  ]);


  return {
    addNewAncestor,
    allAncestors,
    allRelationships,
    fetchAllAncestors,
    fetchAllRelationships,
    getAncestorById,
    getChildrenByAncestorId,
    getChildrenFromRelationships,
    getParentsByAncestorId,
    getParentsFromRelationships,
    getRelationshipsByChildId,
    getRelationshipsByParentId,
    graphData,
    queryRelationshipBetweenAncestors,
    refetchData,
    setToBeSelectedNodeId,
    toBeSelectedNodeId,
    updateAncestor,
  };
};

export default useData;