/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import { Ancestor, QueriedRelationship } from '../types.d';
import { Button } from '../styled';
import theme from '../theme';

import CompoundContext from '../context/CompoundContext';
import DataContext from '../context/DataContext';

import AncestorPicker from './AncestorPicker';

interface RelationshipCheckerProps {
  ancestorA?: Ancestor;
}

const RelationshipChecker: React.FC<RelationshipCheckerProps> = ({
  ancestorA,
}) => {
  const [firstAncestor, setFirstAncestor] = React.useState<Ancestor | undefined>(ancestorA);
  const [secondAncestor, setSecondAncestor] = React.useState<Ancestor>();
  const [result, setResult] = React.useState<QueriedRelationship[]>();

  const { updateAndMoveToDrawerAncestor } = React.useContext(CompoundContext);
  const {
    allAncestors,
    getAncestorById,
    queryRelationshipBetweenAncestors,
  } = React.useContext(DataContext);

  React.useEffect(() => {
    setFirstAncestor(ancestorA);
  }, [
    ancestorA,
  ]);

  React.useEffect(() => {
    if (firstAncestor && secondAncestor) {
      queryRelationshipBetweenAncestors(firstAncestor?.id, secondAncestor.id)
        .then((res) => setResult(res));
    }
  }, [
    firstAncestor,
    secondAncestor,
    queryRelationshipBetweenAncestors,
  ]);

  return (
    <div css={css`
      padding: ${theme.spacing.x5};
    `}>
      <h3 css={css`padding-bottom: ${theme.spacing.x2};`}>Query Relationship</h3>
      <AncestorPicker
        allAncestors={allAncestors}
        handleChange={(id) => setFirstAncestor(getAncestorById(id))}
        name='relationship-checker-first-ancestor'
        value={firstAncestor?.id ?? -1}
      />
      <div
        css={css`
          padding-bottom: ${theme.spacing.base};
          padding-top: ${theme.spacing.base};
        `}
      >
        {
          firstAncestor && secondAncestor && (
            result?.[0]
              ? ` is the ${result[0].relationshipNames[0]} of `
              : ' is not related to '
          )
        }
      </div>
      <AncestorPicker
        allAncestors={allAncestors}
        handleChange={(id) => setSecondAncestor(getAncestorById(id))}
        name='relationship-checker-second-ancestor'
        value={secondAncestor?.id ?? -1}
      />
      {
        firstAncestor && secondAncestor && typeof result?.[0]?.commonAncestorId !== 'undefined' && (
          <div css={css`
            padding-bottom: ${theme.spacing.base};
            padding-top: ${theme.spacing.base};
          `}>
            by way of
            <Button
              onClick={() => updateAndMoveToDrawerAncestor(result[0].commonAncestorId!)}
            >
              {`${getAncestorById(result[0].commonAncestorId)?.name} (${result[0].commonAncestorId})`}
            </Button>
          </div>
        )
      }
      {
        result?.length! >= 2 && result?.slice(1)?.map((rel) => (
          <div
            key={`additional-relationship-${rel.commonAncestorId}`}
          >
            (
              also their {rel.relationshipNames[0]}
              {
                typeof rel.commonAncestorId !== 'undefined' && ` by way of `
              }
              {
                typeof rel.commonAncestorId !== 'undefined' && (
                  <Button
                    onClick={() => updateAndMoveToDrawerAncestor(rel.commonAncestorId!)}
                  >
                    {`${getAncestorById(rel.commonAncestorId)?.name} (${rel.commonAncestorId})`}
                  </Button>
                )
              }
            )
          </div>
        ))
      }
    </div>
  );
};

export default RelationshipChecker;