import React from 'react';

import { Ancestor } from '../types';

import DataContext from '../context/DataContext';
import DrawerContext from '../context/DrawerContext';

const useCompound = () => {
  const {
    getAncestorById,
    getChildrenByAncestorId,
    getParentsByAncestorId,
    graphData,
    setToBeSelectedNodeId,
    toBeSelectedNodeId,
  } = React.useContext(DataContext);

  const {
    drawerAncestor,
    updateDrawerAncestor,
  } = React.useContext(DrawerContext);

  const drawerChildren: Ancestor[] = React.useMemo(() => (
    getChildrenByAncestorId(drawerAncestor?.id)
      .filter((child) => child !== undefined)
      .sort((a, b) => (a?.birth_year || 10000) - (b?.birth_year || 10000)) as Ancestor[]
  ), [
    getChildrenByAncestorId,
    drawerAncestor,
  ]);


  const drawerParents: Ancestor[] = React.useMemo(() => (
    getParentsByAncestorId(drawerAncestor?.id).filter((parent) => parent !== undefined) as Ancestor[]
  ), [
    getParentsByAncestorId,
    drawerAncestor,
  ]);

  const updateAndMoveToDrawerAncestor = React.useCallback((ancestorId: number) => {
    return new Promise((resolve, reject) => {
      const ancestor = getAncestorById(ancestorId);
      if (ancestor) {
        const node = graphData.nodes.find((n) => n.id === ancestorId);
        updateDrawerAncestor(ancestor, node);
        resolve([ancestor, node]);
      }
    });
  }, [
    getAncestorById,
    graphData,
    updateDrawerAncestor,
  ]);

  React.useEffect(() => {
    if (typeof toBeSelectedNodeId !== 'undefined') {
      updateAndMoveToDrawerAncestor(toBeSelectedNodeId)
        .then(() => {
          setToBeSelectedNodeId(undefined);
        });
    }
  }, [
    graphData,
    setToBeSelectedNodeId,
    toBeSelectedNodeId,
    updateAndMoveToDrawerAncestor,
  ]);

  return {
    drawerChildren,
    drawerParents,
    updateAndMoveToDrawerAncestor,
  };
};

export default useCompound;