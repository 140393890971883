/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import { ProviderProps } from './types.d';
import theme from './theme';

export const MOBILE_BREAKPOINT = 1160;

type Icon = {
  color?: string;
  size?: string;
};

export const AddPersonIcon: React.FC<Icon> = ({
  color = "currentColor",
  size = theme.spacing.x6,
}) => {
  return (
    <div css={css`
      display: flex;
      height: ${size};
      width: ${size};
    `}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM504 312V248H440c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V136c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H552v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z" fill={color} /></svg>
    </div>
  );
};

export const EditIcon: React.FC<Icon> = ({
  color = "currentColor",
  size = theme.spacing.x6,
}) => {
  return (
    <div css={css`
      display: flex;
      height: ${size};
      width: ${size};
    `}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" fill={color} /></svg>
    </div>
  );
};

export const DownArrowIcon: React.FC<Icon> = ({
  color = "currentColor",
  size = theme.spacing.x6,
}) => {
  return (
    <div css={css`
      height: ${size};
      width: ${size};
    `}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" fill={color} /></svg>
    </div>
  );
};

export const UpArrowIcon: React.FC<Icon> = ({
  color = "currentColor",
  size = theme.spacing.x6,
}) => {
  return (
    <div css={css`
      height: ${size};
      width: ${size};
    `}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z" fill={color} /></svg>
    </div>
  );
};

export const QuestionMarkIcon: React.FC<Icon> = ({
  color = "currentColor",
  size = theme.spacing.x6,
}) => {
  return (
    <div css={css`
      height: ${size};
      width: ${size};
    `}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm169.8-90.7c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z" fill={color}/></svg>
    </div>
  );
};

const OutlinkIcon: React.FC<Icon> = ({
  color = "currentColor",
  size = theme.spacing.x6,
}) => {
  return (
    <div css={css`
      display: flex;
      height: ${size};
      width: ${size};
    `}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z" fill={color}/></svg>
    </div>
  );
};

type ButtonProps = ProviderProps
  & React.ClassAttributes<HTMLButtonElement>
  & React.ButtonHTMLAttributes<HTMLButtonElement>
  & {
    color?: string;
    size?: 'small' | 'medium' | 'large';
    textOnly?: boolean;
  };

export const Button: React.FC<ButtonProps> = ({
  children,
  color,
  size = 'medium',
  textOnly = true,
  ...props
}) => (
  <button
    css={css`
      align-items: center;
      align-self: center;
      background: none;
      ${!textOnly && `background-color: ${theme.colors.offBlack};`}
      border: ${textOnly ? 'none' : `1px solid ${theme.colors.gray.dark}`};
      border-radius: ${theme.spacing.base};
      color: ${color ?? (textOnly ? theme.colors.blue.base : theme.colors.white)};
      cursor: pointer;
      display: inline-flex;
      font-size: 11px;
      line-height: ${theme.spacing.x4};
      padding: ${textOnly ? theme.spacing.x2 : (
        size === 'medium'
          ? `${theme.spacing.x2} ${theme.spacing.x8}`
          : (size === 'small'
              ? `${theme.spacing.x2} ${theme.spacing.x4}`
              : `${theme.spacing.x4} ${theme.spacing.x12}`
          )
      )};
    
      &:hover {
        border-color: ${color ?? theme.colors.gray.light};
        ${textOnly && `text-decoration: underline;`}
      }
    `}
    {...props}
  >
    {children}
  </button>
);

type OutgoingLinkProps = ProviderProps & { link: string; };

export const OutgoingLink: React.FC<OutgoingLinkProps> = ({
  children,
  link,
}) => {
  return (
    <a
      css={css`
        align-items: center;
        color: ${theme.colors.blue.base};
        display: flex;
        justify-content: center;
        text-decoration: none;
        ${theme.transition.veryShortLinear()}

        &:hover {
          color: #ffffff;
        }
      `}
      href={link}
      rel='noreferrer'
      target='_blank'
    >
      {children}
      &nbsp;&nbsp;
      <OutlinkIcon 
        size={theme.spacing.x5}
      />
    </a>
  )
};

export const KeyboardKey: React.FC<ProviderProps> = ({
  children,
}) => (
  <span css={css`
    background-color: ${theme.colors.gray.dark};
    border: 1px solid ${theme.colors.white};
    border-radius: ${theme.spacing.base};
    font-family: monospace;
    line-height: calc(${theme.spacing.x8} + ${theme.spacing.base});
    padding: ${theme.spacing.x2};
  `}>
    {children}
  </span>
);