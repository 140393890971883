/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import { DriverConfigProps } from '../types.d';
import { MOBILE_BREAKPOINT } from '../styled';
import theme from '../theme';
import useCrypto from '../hooks/useCrypto';

const PANEL_HEIGHT = 280;
const PANEL_WIDTH = 640;

interface ConnectionConfigProps {
  driverConfig?: DriverConfigProps;
  handleChange: (driverConfig: DriverConfigProps) => void;
}

const CONFIG_DEFAULTS = {
  scheme: 'neo4j' as DriverConfigProps["scheme"],
  host: 'localhost',
  port: 7687,
  username: 'neo4j',
  password: 'password',
}

const ConnectionConfig: React.FC<ConnectionConfigProps> = ({
  driverConfig,
  handleChange,
}) => {
  const [internalConfig, setInternalConfig] = React.useState<DriverConfigProps | undefined>(driverConfig);
  const [shareURL, setShareURL] = React.useState<string>();
  const { encrypt } = useCrypto();

  React.useEffect(() => {
    const encryptText = async () => {
      setShareURL(`/?cfg=${await encrypt(JSON.stringify(internalConfig))}`);
    };

    if (internalConfig) {
      encryptText();
    }
  }, [
    encrypt,
    internalConfig,
  ]);

  const updateInternalConfig = React.useCallback(({
    scheme,
    host,
    port,
    username,
    password,
  }: {
    scheme?: DriverConfigProps["scheme"],
    host?: string,
    port?: number,
    username?: string,
    password?: string,
  }) => {
    const newConfig = {
      scheme: scheme ?? internalConfig?.scheme ?? CONFIG_DEFAULTS.scheme,
      host: host ?? internalConfig?.host ?? CONFIG_DEFAULTS.host,
      port: port ?? internalConfig?.port ?? CONFIG_DEFAULTS.port,
      username: username ?? internalConfig?.username ?? CONFIG_DEFAULTS.username,
      password: password ?? internalConfig?.password ?? CONFIG_DEFAULTS.password,
    }
    setInternalConfig(newConfig);
  }, [
    internalConfig,
  ]);

  return (
    <div css={css`
      background-color: ${theme.colors.black};
      border: 1px solid ${theme.colors.white};
      border-radius: ${theme.spacing.base};
      padding: ${theme.spacing.x3};
      position: absolute;
      width: ${PANEL_WIDTH}px;
      height: ${PANEL_HEIGHT}px;
      z-index: 5;

      @media(min-width: ${MOBILE_BREAKPOINT}px) {
        top: calc(50% - ${PANEL_HEIGHT / 2}px);
        left: calc(50% - ${PANEL_WIDTH / 2}px);
      }
    `}>
      <div>
        <label htmlFor='scheme'>Scheme</label>
        <select
          defaultValue="neo4j+s"
          name='scheme'
          onChange={(e) => updateInternalConfig({
            scheme: e.target.value as DriverConfigProps["scheme"],
          })}
        >
          <option key="neo4j" value="neo4j">neo4j</option>
          <option key="neo4j+s" value="neo4j+s">neo4j+s</option>
          <option key="neo4j+scc" value="neo4j+scc">neo4j+scc</option>
          <option key="bolt" value="bolt">bolt</option>
          <option key="bolt+s" value="bolt+s">bolt+s</option>
          <option key="bolt+scc" value="bolt+scc">bolt+scc</option>
        </select>
      </div>
      <div>
        <label htmlFor='host'>Host</label>
        <input
          name='host'
          onChange={(e) => updateInternalConfig({
            host: e.target.value,
          })}
          type='string'
          value={internalConfig?.host}
        />
      </div>
      <div>
        <label htmlFor='port'>Port</label>
        <input
          name='port'
          onChange={(e) => updateInternalConfig({
            port: parseInt(e.target.value),
          })}
          type='number'
          value={internalConfig?.port}
        />
      </div>
      <div>
        <label htmlFor='username'>Username</label>
        <input
          name='username'
          onChange={(e) => updateInternalConfig({
            username: e.target.value,
          })}
          type='string'
          value={internalConfig?.username}
        />
      </div>
      <div>
        <label htmlFor='password'>Password</label>
        <input
          name='password'
          onChange={(e) => updateInternalConfig({
            password: e.target.value,
          })}
          type='password'
          value={internalConfig?.password}
        />
      </div>
      <button onClick={() => internalConfig && handleChange(internalConfig)}>Save</button>
      <a href={shareURL}>Share URL</a>
    </div>
  );
};

export default ConnectionConfig;