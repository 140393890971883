/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Fuse from 'fuse.js'
import React from 'react';

import { Ancestor } from '../types.d';
import theme from '../theme';

const PICKER_WIDTH = 200;

interface AncestorPickerProps {
  allAncestors: Ancestor[];
  handleChange: (id: number) => void;
  name: string;
  placeholder?: string;
  searchInputRef?: React.RefObject<HTMLInputElement>;
  value: number;
};

const AncestorPicker: React.FC<AncestorPickerProps> = ({
  allAncestors,
  handleChange,
  name,
  placeholder,
  searchInputRef,
  value,
}) => {
  const [highlightedAncestorIndex, setHighlightedAncestorIndex] = React.useState(0);
  const [inputValue, setInputValue] = React.useState<string | number>("");

  const selectAncestor = React.useCallback((id: number) => {
    handleChange(id);
    setHighlightedAncestorIndex(0);
  }, [
    handleChange,
  ]);

  const fuse = React.useMemo(() => (
    new Fuse(allAncestors, {
      ignoreLocation: true,
      threshold: 0.3,
      keys: [
        "name",
        "tags",
        "birth_place",
        "id",
        "birth_year",
        "death_year",
      ]
    })
  ), [
    allAncestors,
  ]);

  const searchResults = React.useMemo(() => (
    fuse.search(inputValue.toString())
  ), [
    fuse,
    inputValue,
  ]);

  const visibleOptions = React.useMemo(() => (
    searchResults.length > 0
      ? searchResults.map((r) => r.item)
      : [...allAncestors].sort((a, b) => b.id - a.id)
  ), [
    allAncestors,
    searchResults,
  ]);

  React.useEffect(() => {
    const ancestor = allAncestors.find((a) => a.id === value);
    if (ancestor) {
      setInputValue(`${ancestor.name || 'Unknown'} (${ancestor.id})`);
    }
    if (searchInputRef && searchInputRef.current) {
      searchInputRef.current.blur();
    }
  }, [
    allAncestors,
    searchInputRef,
    value,
  ]);

  return (
    <div
      css={css`
        align-items: center;
        display: flex;
        flex-direction: column;
        position: relative;

        ul {
          display: none;
        }

        input:focus + ul,
        input:active + ul,
        ul:focus, ul:active {
          display: flex;
          flex-direction: column;
          margin: 0;
          padding: 0;
          position: absolute;
          top: calc(${theme.spacing.x8} - 2px);
          z-index: 1;
        }
      `}
    >
      <input
        aria-activedescendant=""
        aria-autocomplete="both"
        aria-describedby="initInstr"
        aria-owns="results"
        css={css`
          appearance: none;
          background-color: ${theme.colors.offBlack};
          border: 1px solid ${theme.colors.gray.dark};
          border-radius: ${theme.spacing.base};
          font-size: 11px;
          outline: none;
          line-height: ${theme.spacing.x4};
          padding: ${theme.spacing.x2};
          width: ${PICKER_WIDTH}px;
          z-index: 0;

          &:focus {
            border-color: ${theme.colors.white};
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }

          &:hover {
            border-color: ${theme.colors.white};
          }
        `}
        id={`${name}-search`}
        key={`${name}-search`}
        onChange={(e) => {
          setInputValue(e.target.value);
          setHighlightedAncestorIndex(0);
        }}
        onFocus={(e) => e.currentTarget.select()}
        onKeyDown={(e) => {
          if (e.key === "ArrowDown") {
            const newIndex = highlightedAncestorIndex < visibleOptions.length - 1 ? highlightedAncestorIndex + 1 : 0;
            setHighlightedAncestorIndex(newIndex);
            document.getElementById(visibleOptions[newIndex].id.toString())?.scrollIntoView({ block: "nearest" });
          }
          if (e.key === "ArrowUp") {
            const newIndex = highlightedAncestorIndex > 0 ? highlightedAncestorIndex - 1 : visibleOptions.length - 1;
            setHighlightedAncestorIndex(newIndex);
            document.getElementById(visibleOptions[newIndex].id.toString())?.scrollIntoView({ block: "nearest" });
          }
          if (e.key === "Enter") {
            selectAncestor(visibleOptions[highlightedAncestorIndex].id);
            e.currentTarget.blur();
          }
        }}
        placeholder={placeholder ?? "Pick an Ancestor..."}
        ref={searchInputRef}
        type="string"
        value={inputValue}
      />
      <ul
        css={css`
          height: fit-content;
          max-height: 250px;
          overflow-y: scroll;
        `}
        id="results"
        role="listbox"
      >
        {
          visibleOptions
            .map((ancestor, index) => (
              <li
                aria-selected="false"
                css={css`
                  background-color: ${theme.colors.gray.dark};
                  border: 1px solid ${theme.colors.white};
                  border-bottom: none;
                  color: ${theme.colors.white};
                  cursor: pointer;
                  font-size: 11px;
                  line-height: ${theme.spacing.x4};
                  list-style-type: none;
                  padding: ${theme.spacing.x2};
                  text-align: center;
                  width: ${PICKER_WIDTH}px;

                  ${index === highlightedAncestorIndex && `background-color: ${theme.colors.gray.base};`}

                  &:hover {
                    background-color: ${theme.colors.gray.base};
                  }

                  &:last-of-type {
                    border-bottom: 1px solid ${theme.colors.white};
                    border-bottom-left-radius: ${theme.spacing.base};
                    border-bottom-right-radius: ${theme.spacing.base};
                  }
                `}
                id={ancestor.id.toString()}
                key={ancestor.id}
                onClick={(e) => selectAncestor(e.currentTarget.value)}
                role="option"
                value={ancestor.id}
              >
                {ancestor?.name || 'Unknown'} ({ancestor.id})
              </li>
            ))
        }
      </ul>
    </div>
  );
};

export default AncestorPicker;