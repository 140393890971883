import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { Neo4jProvider, createDriver } from 'use-neo4j';

import { DriverConfigProps, ProviderProps } from '../types.d';

import DrawerContext from '../context/DrawerContext';

import useCrypto from '../hooks/useCrypto';
import useDrawer from '../hooks/useDrawer';

import ConnectionConfig from './ConnectionConfig';

const ProviderLayer: React.FC<ProviderProps> = ({ children }) => {
  const [driverConfig, setDriverConfig] = React.useState<DriverConfigProps>();
  const [isConfigPanelOpen, setIsConfigPanelOpen] = React.useState(false);
  const [isInitialized, setIsInitialized] = React.useState(false);

  const { decrypt } = useCrypto();

  const handleChange = React.useCallback((driverConfig: DriverConfigProps) => {
    setDriverConfig(driverConfig);
    setIsConfigPanelOpen(false);
    window.localStorage.setItem('family-tree-neo4j-config', JSON.stringify(driverConfig))
  }, []);

  useHotkeys('ctrl+shift+c', () => setIsConfigPanelOpen((v) => !v));

  React.useEffect(() => {
    const urlQueryParams = new URLSearchParams(window.location.search);
    const encryptedConfig = decodeURIComponent(urlQueryParams.get('cfg') || '');

    const decryptText = async () => {
      handleChange(JSON.parse(await decrypt(encryptedConfig)));
      window.history.pushState({ path: window.location.href.split("?")[0] }, '', window.location.href.split("?")[0]);
    };

    if (encryptedConfig) {
      decryptText();
    }
  }, [
    decrypt,
    handleChange,
  ]);

  React.useEffect(() => {
    if (typeof driverConfig === 'undefined') {
      setIsConfigPanelOpen(true);
    }
  }, [
    driverConfig,
  ]);

  React.useEffect(() => {
    if (window) {
      const storageValue = window.localStorage.getItem('family-tree-neo4j-config');
      if (storageValue) {
        setDriverConfig(JSON.parse(storageValue));
        setIsConfigPanelOpen(false);
      }
    }
  }, []);

  const driver = React.useMemo(
    () => (
      typeof driverConfig !== 'undefined'
        ? createDriver(
          driverConfig.scheme,
          driverConfig.host,
          driverConfig.port,
          driverConfig.username,
          driverConfig.password
        )
        : undefined
    ), [
    driverConfig,
  ]
  );

  React.useEffect(
    () => {
      driver
        ?.verifyConnectivity()
        .then(() => {
          setIsInitialized(true);
        })
        .catch((err) => {
          console.log('neo4j connection error: ' + err);
        });
    },
    [
      driver,
    ]
  );

  const drawerContextValue = useDrawer();

  return (
    <div>
      {
        isConfigPanelOpen && (
          <ConnectionConfig
            driverConfig={driverConfig}
            handleChange={handleChange}
          />
        )
      }
      {
        isInitialized && (
          <Neo4jProvider driver={driver}>
            <DrawerContext.Provider
              value={drawerContextValue}
            >
              {children}
            </DrawerContext.Provider>
          </Neo4jProvider>
        )
      }
    </div>
  );
};

export default ProviderLayer;