const useCrypto = () => {
  const key = "somethinSomethin";
  const encrypt = async (str: string) => {
    var keyBytes = new TextEncoder().encode(key);
    var textBytes = new TextEncoder().encode(str);

    const encrypted = await crypto.subtle.importKey("raw", keyBytes, "AES-CBC", false, ["encrypt"]).then(function(key) {
      return crypto.subtle.encrypt({ name: "AES-CBC", iv: new Uint8Array(16) }, key, textBytes).then(function(encryptedBytes) {
        return btoa(String.fromCharCode.apply(null, Array.from(new Uint8Array(encryptedBytes))));
      });
    });

    return encrypted;
  }

  const decrypt = async (encryptedBase64: string) => {
    var keyBytes = new TextEncoder().encode(key);
    var encryptedBytes = new Uint8Array(atob(encryptedBase64).split("").map(function(c) { return c.charCodeAt(0); }));

    const decrypted = await crypto.subtle.importKey("raw", keyBytes, "AES-CBC", false, ["decrypt"]).then(function(key) {
      return crypto.subtle.decrypt({ name: "AES-CBC", iv: new Uint8Array(16) }, key, encryptedBytes).then(function(decryptedBytes) {
        return new TextDecoder().decode(decryptedBytes);
      });
    });

    return decrypted;
  }

  return {
    decrypt,
    encrypt,
  };
};

export default useCrypto;