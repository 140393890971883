export const toLetter = (number: number) => String.fromCharCode(number + 98);

const RELATIONSHIP_TYPES = [
  [
    () => ['Self', 'Self'],
    () => ['Child', 'Parent'],
    () => ['Grandchild', 'Grandparent'],
    () => ['Great Grandchild', 'Great Grandparent'],
    (a: number, b: number) => [`${b-2}x Great Grandchild`, `${b-2}x Great Grandparent`],
  ],
  [
    () => ['Parent', 'Child'],
    () => ['Sibling', 'Sibling'],
    () => ['Niece/Nephew', 'Aunt/Uncle'],
    () => ['Great Niece/Nephew', 'Great Aunt/Uncle'],
    (a: number, b: number) => [`${b-2}x Great Niece/Nephew`, `${b-2}x Great Aunt/Uncle`],
  ],
  [
    () => ['Grandparent', 'Grandchild'],
    () => ['Aunt/Uncle', 'Niece/Nephew'],
    () => ['Cousin', 'Cousin'],
    (a: number, b: number) => [`${a-1}x Cousin, ${b-a} times removed`, `${a-1}x Cousin, ${b-a} times removed`],
  ],
  [
    () => ['Great Grandparent', 'Great Grandchild'],
    () => ['Great Aunt/Uncle', 'Great Niece/Nephew'],
    (a: number, b: number) => [`${b-1}x Cousin, ${a-b} times removed`, `${b-1}x Cousin, ${a-b} times removed`],
    (a: number, b: number) => [`${b-1}x Cousin`, `${b-1}x Cousin`],
    (a: number, b: number) => [`${a-1}x Cousin, ${b-a} times removed`, `${a-1}x Cousin, ${b-a} times removed`],
  ],
  [
    (a: number, b: number) => [`${a-2}x Great Grandparent`, `${a-2}x Great Grandchild`],
    (a: number, b: number) => [`${a-2}x Great Aunt/Uncle`, `${a-2}x Great Niece/Nephew`],
    (a: number, b: number) => [`${b-1}x Cousin, ${a-b} times removed`, `${b-1}x Cousin, ${a-b} times removed`],
    (a: number, b: number) => [`${b-1}x Cousin, ${a-b} times removed`, `${b-1}x Cousin, ${a-b} times removed`],
    (a: number, b: number) => [
      `${(a >= b ? b : a)-1}x Cousin, ${(a >= b ? a : b)-(a >= b ? b : a)} times removed`,
      `${(a >= b ? b : a)-1}x Cousin, ${(a >= b ? a : b)-(a >= b ? b : a)} times removed`,
    ],
    (a: number, b: number) => [
      `${(a >= b ? b : a)-1}x Cousin, ${(a >= b ? a : b)-(a >= b ? b : a)} times removed`,
      `${(a >= b ? b : a)-1}x Cousin, ${(a >= b ? a : b)-(a >= b ? b : a)} times removed`,
    ],
  ],
];

export const getRelationshipType = (
  firstSegmentLength: number,
  secondSegmentLength: number,
) => {
  if (firstSegmentLength === secondSegmentLength) {
    if (firstSegmentLength >= 3) {
      return [`${firstSegmentLength - 1}x Cousin`, `${firstSegmentLength - 1}x Cousin`];
    }
  }
  const rowIndex = Math.min(firstSegmentLength, RELATIONSHIP_TYPES.length - 1);
  const columnIndex = Math.min(secondSegmentLength, RELATIONSHIP_TYPES[rowIndex].length - 1);
  return RELATIONSHIP_TYPES[rowIndex][columnIndex](firstSegmentLength, secondSegmentLength);
};

export const getDisplayText = (text: string) => (
  text.replaceAll('_', ' ').replaceAll(/(\b[a-z](?!\s))/g, (x) => x.toUpperCase())
);