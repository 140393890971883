/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook'; 

import { PageEnum } from '../types.d';
import { MOBILE_BREAKPOINT, Button, AddPersonIcon } from '../styled';
import theme from '../theme';

import CompoundContext from '../context/CompoundContext';
import DataContext from '../context/DataContext';
import DrawerContext from '../context/DrawerContext';

import AncestorForm from './AncestorForm';
import AncestorPicker from './AncestorPicker';
import NodeInfoPage from './NodeInfoPage';

const Drawer = () => {
  const { updateAndMoveToDrawerAncestor } = React.useContext(CompoundContext);
  const { allAncestors } = React.useContext(DataContext);
  const {
    currentPage,
    isDrawerOpen,
    isMobile,
    setCurrentPage,
    drawerAncestor,
    drawerWidth,
    toggleIsDrawerOpen,
  } = React.useContext(DrawerContext);

  useHotkeys('space', () => toggleIsDrawerOpen());

  const onPageToggleClick = React.useCallback(() => {
    if (currentPage === PageEnum.ADD_NEW) {
      setCurrentPage(PageEnum.NODE_INFO);
    } else {
      setCurrentPage(PageEnum.ADD_NEW);
    }
  }, [
    currentPage,
    setCurrentPage,
  ]);

  return isDrawerOpen ? (
    <div css={css`
      display: flex;
      flex-direction: column;
      padding: ${theme.spacing.x3} ${theme.spacing.x3} 0;
      ${theme.transition.shortEaseOut()}

      @media(min-width: ${MOBILE_BREAKPOINT}px) {
        height: calc(${window.innerHeight}px - ${theme.spacing.x3} - 20px);
        padding-left: ${theme.spacing.x5};
        width: ${drawerWidth}px;
      }

      @media(max-width: ${MOBILE_BREAKPOINT - 1}px) {
        height: 100%;
      }

      h2, h3, h4 {
        margin: 2px;
      }
    `}>
      <div css={css`
        display: flex;
        justify-content: space-between;
      `}>
        <Button
          size='small'
          textOnly={false}
          onClick={() => toggleIsDrawerOpen()}
        >
          {isMobile ? 'Close' : '>> Close'}
        </Button>
        <div css={css`
          column-gap: ${theme.spacing.x2};
          display: flex;
        `}>
          <Button
            onClick={() => onPageToggleClick()}
            size='small'
            textOnly={false}
          >
            {currentPage === PageEnum.ADD_NEW ? (
              isMobile ? 'Back' : PageEnum.NODE_INFO
            ) : (
              isMobile ? '' : PageEnum.ADD_NEW
            )}
            {currentPage === PageEnum.NODE_INFO ? (
              <div css={css`
                ${!isMobile && `padding-left: ${theme.spacing.base};`}
              `}>
                <AddPersonIcon size={theme.spacing.x4} />
              </div>
            ) : <span></span>}
          </Button>
          <AncestorPicker 
            allAncestors={allAncestors}
            handleChange={(id) => updateAndMoveToDrawerAncestor(id)}
            name="drawerAncestor"
            placeholder="Find an ancestor..."
            value={drawerAncestor?.id ?? 2}
          />
        </div>
      </div>
      <div css={css`
        display: flex;
        flex-grow: 1;
        height: calc(100% - 54px);
        justify-content: center;
        overflow-y: scroll;
        padding-top: ${theme.spacing.x5};
        text-align: center;
      `}>
        {
          currentPage === PageEnum.NODE_INFO && (
            <NodeInfoPage />
          )
        }
        {
          currentPage === PageEnum.ADD_NEW && (
            <AncestorForm
              onSubmit={() => setCurrentPage(PageEnum.NODE_INFO)}
            />
          )
        }
      </div>
    </div>
  ) : null;
};

export default Drawer;