import React from 'react';

import {
  Ancestor,
  GraphData,
  NewAncestor,
  QueriedRelationship,
  Relationship
} from '../types.d';

interface DataContextProps {
  addNewAncestor: (ancestor: NewAncestor, child_ids?: number[], parent_ids?: number[]) => void;
  allAncestors: Ancestor[];
  allRelationships: Relationship[];
  fetchAllAncestors: () => Promise<unknown>;
  fetchAllRelationships: () => void;
  getAncestorById: (id: string | number | undefined) => Ancestor | undefined;
  getChildrenByAncestorId: (id: string | number | undefined) => (Ancestor | undefined)[];
  getChildrenFromRelationships: (relationships: Relationship[]) => (Ancestor | undefined)[];
  getParentsByAncestorId: (id: string | number | undefined) => (Ancestor | undefined)[];
  getParentsFromRelationships: (relationships: Relationship[]) => (Ancestor | undefined)[];
  getRelationshipsByChildId: (id: string | number | undefined) => Relationship[];
  getRelationshipsByParentId: (id: string | number | undefined) => Relationship[];
  graphData: GraphData;
  queryRelationshipBetweenAncestors: (ancestorAId: number, ancestorBId: number) => Promise<QueriedRelationship[]>;
  refetchData: () => void;
  setToBeSelectedNodeId: (id: number | undefined) => void,
  toBeSelectedNodeId?: number,
  updateAncestor: (ancestor: NewAncestor, id: number, child_ids?: number[], parent_ids?: number[]) => void;
}

const DataContext = React.createContext<DataContextProps>({
  addNewAncestor: (ancestor: NewAncestor, child_ids?: number[], parent_ids?: number[]) => {},
  allAncestors: [],
  allRelationships: [],
  fetchAllAncestors: () => new Promise(() => {}),
  fetchAllRelationships: () => {},
  getAncestorById: (id: string | number | undefined) => undefined,
  getChildrenByAncestorId: (id: string | number | undefined) => [],
  getChildrenFromRelationships: (relationships: Relationship[]) => [],
  getParentsByAncestorId: (id: string | number | undefined) => [],
  getParentsFromRelationships: (relationships: Relationship[]) => [],
  getRelationshipsByChildId: (id: string | number | undefined) => [],
  getRelationshipsByParentId: (id: string | number | undefined) => [],
  graphData: { nodes: [], links: [] },
  queryRelationshipBetweenAncestors: (ancestorAId: number, ancestorBId: number) => new Promise(() => {}),
  refetchData: () => {},
  setToBeSelectedNodeId: (id: number | undefined) => {},
  updateAncestor: (ancestor: NewAncestor, id: number, child_ids?: number[], parent_ids?: number[]) => {},
});

export default DataContext;