/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import { MOBILE_BREAKPOINT } from '../styled';

import CompoundContext from '../context/CompoundContext';

import useCompound from '../hooks/useCompound';

import GlobalSearch from './GlobalSearch';
import Graph from './Graph';
import Drawer from './Drawer';

const GraphWrapper = () => {
  return (
    <CompoundContext.Provider
      value={useCompound()}
    >
      <GlobalSearch />
      <div css={css`
        display: flex;

        @media(max-width: ${MOBILE_BREAKPOINT - 1}px) {
          flex-direction: column;
        }
      `}>
        <Graph />
        <Drawer />
      </div>
    </CompoundContext.Provider>
  );
};

export default GraphWrapper;