import React from 'react';

import {
  Ancestor,
  NodeObject,
  PageEnum,
} from '../types.d';

import { MOBILE_BREAKPOINT } from '../styled';

import useMediaQuery from './useMediaQuery';

const SIDEBAR_HEIGHT = 350;
const SIDEBAR_WIDTH = MOBILE_BREAKPOINT / 2;

const useDrawer = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedAncestor, setSelectedAncestor] = React.useState<Ancestor>();
  const [currentPage, setCurrentPage] = React.useState<PageEnum>(PageEnum.NODE_INFO);

  // eslint-disable-next-line
  const graphRef = React.useRef<any>();

  const isMobile = useMediaQuery(`(max-width: ${MOBILE_BREAKPOINT - 1}px)`);

  const toggleIsDrawerOpen = React.useCallback(
    () => setIsOpen(val => !val), [
      setIsOpen,
  ]);

  const updateDrawerAncestor = React.useCallback(
    (ancestor: Ancestor, node?: NodeObject) => {
      setSelectedAncestor(ancestor)
      if (node) {
        graphRef?.current?.cameraPosition({
          x: (node?.x || 0) - 200,
          y: (node?.y || 0),
          z: (node?.z || 0),
        }, {
          x: node?.x || 0,
          y: node?.y || 0,
          z: node?.z || 0,
        }, 800);
      }
      setCurrentPage(PageEnum.NODE_INFO);
    }, [
      graphRef,
      setSelectedAncestor,
    ]
  );

  return {
    currentPage,
    graphRef,
    isDrawerOpen: isOpen,
    isMobile,
    setCurrentPage,
    drawerAncestor: selectedAncestor,
    drawerHeight: isOpen && isMobile ? SIDEBAR_HEIGHT : 0,
    drawerWidth: isOpen && !isMobile ? SIDEBAR_WIDTH : 0,
    toggleIsDrawerOpen,
    updateDrawerAncestor,
  };
}

export default useDrawer;