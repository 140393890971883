/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import { FormType } from '../types.d';
import {
  Button,
  EditIcon,
  MOBILE_BREAKPOINT,
  OutgoingLink,
} from '../styled';
import theme from '../theme';

import CompoundContext from '../context/CompoundContext';
import DrawerContext from '../context/DrawerContext';

import AncestorForm from './AncestorForm';
import RelationshipChecker from './RelationshipChecker';

const MarkdownPreview = React.lazy(() => import('@uiw/react-markdown-preview'));

const familyMemberRowStyle = css`
  display: grid;
  min-height: 34px;
  padding-top: ${theme.spacing.base};

  &:last-child {
    padding-bottom: ${theme.spacing.x2};
  }
`;

const familyMemberWrapperStyle = css`
  border: 1px solid ${theme.colors.white};
  border-style: solid;
  border-right-width: 0;
  display: flex;
  justify-content: center;

  &:first-child {
    border-bottom-left-radius: ${theme.spacing.base};
    border-top-left-radius: ${theme.spacing.base};
  }

  &:last-child {
    border-bottom-right-radius: ${theme.spacing.base};
    border-top-right-radius: ${theme.spacing.base};
    border-right-width: 1px;
  }
`;

const NodeInfoPage = () => {
  const [isEditModeActive, setIsEditModeActive] = React.useState(false);

  const {
    drawerChildren,
    drawerParents,
    updateAndMoveToDrawerAncestor,
  } = React.useContext(CompoundContext);
  const { drawerAncestor } = React.useContext(DrawerContext);

  const mother = drawerParents.find((p) => p.gender === 'female');
  const father = drawerParents.find((p) => p.gender === 'male');

  useHotkeys('e', () => setIsEditModeActive((isActive) => !isActive));

  const heading = `${drawerAncestor?.name || 'Unknown'} (${drawerAncestor?.id || 0})`;

  return (
    <div css={css`
      display: flex;
      flex-grow: 1;
      justify-content: center;
    `}>
      {
        !isEditModeActive ? (
          <div css={css`
            display: flex;
            flex: 1;
            flex-direction: column;

            @media(min-width: ${MOBILE_BREAKPOINT}px) {
              max-height: ${window.innerHeight}px;
            }

            @media(max-width: ${MOBILE_BREAKPOINT - 1}px) {
              max-width: calc(${window.innerWidth}px - (${theme.spacing.x5} * 2));
            }
          `}>
            <h2 css={css`
              padding-bottom: ${theme.spacing.base};
            `}>
              {
                drawerAncestor?.link ? (
                  <OutgoingLink
                    link={drawerAncestor.link}
                  >
                    {heading}
                  </OutgoingLink>
                ) : heading
              }
            </h2>
            <h3>{drawerAncestor?.birth_year || '????'} - {drawerAncestor?.death_year || '????'}</h3>
            <h3>{drawerAncestor?.birth_place || 'Birthplace Unknown'}</h3>
            <Button
              color={theme.colors.gray.light}
              onClick={() => setIsEditModeActive(true)}
            >
              Edit Ancestor
              <div css={css`
                padding-left: ${theme.spacing.base};
              `}>
                <EditIcon size={theme.spacing.x3} />
              </div>
            </Button>
            <div>
              <div
                css={css`
                  display: grid;
                  grid-template-columns: repeat(2, 50%);
                  padding-top: ${theme.spacing.base};

                  span {
                    text-align: left;
                  }
              `}>
                <span>Mother</span>
                <span>Father</span>
              </div>
              <div css={[
                familyMemberRowStyle,
                css`grid-template-columns: repeat(2, 50%);`
              ]}>
                <div css={familyMemberWrapperStyle}>
                  {
                    mother && (
                      <Button
                        color={theme.colors.white}
                        onClick={() => updateAndMoveToDrawerAncestor(mother.id)}
                      >
                        {mother.name || 'Unknown'} ({mother.id})
                      </Button>
                    )
                  }
                </div>
                <div css={familyMemberWrapperStyle}>
                  {
                    father && (
                      <Button
                        color={theme.colors.white}
                        onClick={() => updateAndMoveToDrawerAncestor(father.id)}
                      >
                        {father.name || 'Unknown'} ({father.id})
                      </Button>
                    )
                  }
                </div>
              </div>
              <div
                css={css`
                  display: grid;
                  padding-top: ${theme.spacing.x2};

                  span {
                    min-height: 18px;
                    text-align: left;
                  }
              `}>
                <span>{drawerChildren?.length > 0 && `Children`}</span>
              </div>
              <div css={[
                familyMemberRowStyle,
                css`grid-template-columns: repeat(${drawerChildren.length}, ${100 / drawerChildren.length}%);`
              ]}>
                {drawerChildren?.map((child) => (
                  <div 
                    css={familyMemberWrapperStyle}
                    key={`${child.id}-child-link`}
                  >
                    <Button
                      color={theme.colors.white}
                      onClick={() => updateAndMoveToDrawerAncestor(child.id)}
                    >
                      {child.name} ({child.id})
                    </Button>
                  </div>
                ))}
              </div>
            </div>
            <div css={css`
              flex-grow: 1;
              overflow-wrap: anywhere;
              overflow-y: scroll;
              padding-top: ${theme.spacing.x2};
            `}>
              {
                drawerAncestor?.notes && (
                  <div>
                    <h4>Notes</h4>
                    <React.Suspense fallback={<div>...</div>}>
                      <MarkdownPreview
                        css={css`
                          &.wmde-markdown {
                            background-color: ${theme.colors.black};
                            color: ${theme.colors.white};
                            padding: ${theme.spacing.base} 0;
                            text-align: left;

                            a {
                              color: ${theme.colors.blue.base};
                            }
                          }                          
                        `}
                        source={drawerAncestor.notes}
                      />
                    </React.Suspense>
                  </div>
                )
              }
              <div>
                <RelationshipChecker
                  ancestorA={drawerAncestor}
                />
              </div>
            </div>
          </div>
        ) : (
          <AncestorForm
            ancestor={drawerAncestor}
            children={drawerChildren.map((c) => c.id)}
            onSubmit={() => setIsEditModeActive(false)}
            parents={drawerParents.map((p) => p.id)}
            type={FormType.EDIT_ANCESTOR}
          />
        )
      }
    </div>
  );
};

export default NodeInfoPage;