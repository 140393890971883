import React from 'react';

import { ProviderProps } from '../types.d';

import DataContext from '../context/DataContext';

import useData from '../hooks/useData';

const DataProvider: React.FC<ProviderProps> = ({ children }) => (
  <DataContext.Provider
    value={useData()}
  >
    {children}
  </DataContext.Provider>
);

export default DataProvider;