/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import theme from '../theme';

import CompoundContext from '../context/CompoundContext';
import DataContext from '../context/DataContext';
import DrawerContext from '../context/DrawerContext';

import AncestorPicker from './AncestorPicker';

const GlobalSearch = () => {
  const [shouldRenderGlobalSearch, setShouldRenderGlobalSearch] = React.useState(false);
  const searchInputRef = React.useRef<HTMLInputElement>(null);

  const {
    drawerChildren,
    drawerParents,
    updateAndMoveToDrawerAncestor,
  } = React.useContext(CompoundContext);
  const { allAncestors } = React.useContext(DataContext);
  const { drawerAncestor } = React.useContext(DrawerContext);

  useHotkeys('ctrl+space', () => setShouldRenderGlobalSearch((bool) => !bool));
  useHotkeys('o', () => drawerAncestor?.link && window.open(drawerAncestor?.link, '_blank'));

  useHotkeys(['1', 'shift+m'], () => {
    const mother = drawerParents.find((p) => p.gender === 'female');
    if (mother) {
      updateAndMoveToDrawerAncestor(mother.id);
    }
  });

  useHotkeys(['2', 'shift+f'], () => {
    const father = drawerParents.find((p) => p.gender === 'male');
    if (father) {
      updateAndMoveToDrawerAncestor(father.id);
    }
  });

  const childHotkeys = Array.from(Array(drawerChildren.length+1).keys()).slice(1).map((number) => `shift+${number}`);

  useHotkeys(childHotkeys, (e, h) => {
    const childIndex = parseInt(h?.keys?.[0] || '0') - 1;
    if (childIndex > -1) {
      updateAndMoveToDrawerAncestor(drawerChildren[childIndex].id);
    }
  });

  React.useEffect(() => {
    if (shouldRenderGlobalSearch) {
      searchInputRef?.current?.focus();
    }
  }, [
    searchInputRef,
    shouldRenderGlobalSearch,
  ]);

  return shouldRenderGlobalSearch
    ? (
      <div css={css`
        background-color: ${theme.colors.black};
        border: 1px solid ${theme.colors.white};
        border-radius: ${theme.spacing.base};
        padding: ${theme.spacing.x3};
        position: absolute;
        top: calc(50% - 9.5px);
        left: calc(50% - 103px);
        z-index: 2;
      `}>
        <AncestorPicker
          allAncestors={allAncestors}
          handleChange={(id) => {
            updateAndMoveToDrawerAncestor(id);
            setShouldRenderGlobalSearch(false);
          }}
          name="drawerAncestor"
          placeholder="Find an ancestor..."
          searchInputRef={searchInputRef}
          value={drawerAncestor?.id ?? 2}
        />
      </div>
    )
    : null;
};

export default GlobalSearch;