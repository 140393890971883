export type Maybe<T> = T | null;

export type OptionalKeys<T extends object> = { [P in keyof T]: {} extends Pick<T, P> ? P : never }[keyof T]

export type NewAncestor = object & {
  name?: string;
  birth_place?: string;
  birth_year?: number;
  death_year?: number;
  gender?: 'male' | 'female';
  link?: string;
  tags?: string;
  notes?: string;
};

export const DEFAULT_NEW_ANCESTOR: NewAncestor = {
  name: '',
  birth_place: '',
  birth_year: 0,
  death_year: 0,
  gender: 'male',
  link: '',
  tags: '',
  notes: '',
};

export type Ancestor = NewAncestor & {
  id: number;
};

export type Relationship = object & {
  child_id: number;
  parent_id: number;
};

export interface ProviderProps {
  children: (JSX.Element | number | string | undefined) | (JSX.Element | number | string | undefined)[];
};

export enum PageEnum {
  ADD_NEW = 'Add New Ancestor',
  NODE_INFO = 'View Current Node Info',
};

export enum FormType {
  NEW_ANCESTOR = 'Add New Ancestor',
  EDIT_ANCESTOR = 'Edit Ancestor',
};

export interface DriverConfigProps {
  scheme: 'bolt' | 'bolt+s' | 'bolt+scc' | 'neo4j' | 'neo4j+s' | 'neo4j+scc';
  host: string;
  port: number;
  username: string;
  password: string;
}

export type InputType = 'select' | 'string' | 'number' | 'longtext';

export type QueriedRelationship = {
  commonAncestorId?: number;
  relationshipNames: string[];
};

/////////////////////////
/// react-force-graph ///
/////////////////////////

export type Coords = {
  x: number;
  y: number;
  z: number;
};

export type NodeObject = object & {
  id?: string | number;
  label?: string;
  x?: number;
  y?: number;
  z?: number;
  vx?: number;
  vy?: number;
  vz?: number;
  fx?: number;
  fy?: number;
  fz?: number;
};

export interface LinkObject {
  source?: string | number | NodeObject;
  target?: string | number | NodeObject;
};

export interface GraphData {
  nodes: NodeObject[];
  links: LinkObject[];
};