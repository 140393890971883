import React from "react";

import DataProvider from './DataProvider';
import GraphWrapper from './GraphWrapper';
import ProviderLayer from './ProviderLayer';

const FamilyTreeApp = () => {
  return (
    <ProviderLayer>
      <DataProvider>
        <GraphWrapper />
      </DataProvider>
    </ProviderLayer>
  );
}

export default FamilyTreeApp;