import React from 'react';

import {
  Ancestor,
  NodeObject,
  PageEnum,
} from '../types.d';

interface DrawerContextProps {
  currentPage: PageEnum;
  graphRef?: React.MutableRefObject<any>;
  isDrawerOpen: boolean;
  isMobile: boolean;
  setCurrentPage: React.Dispatch<React.SetStateAction<PageEnum>>;
  drawerAncestor?: Ancestor;
  drawerHeight: number;
  drawerWidth: number;
  toggleIsDrawerOpen: () => void;
  updateDrawerAncestor: (ancestor: Ancestor, node?: NodeObject) => void;
}

const DrawerContext = React.createContext<DrawerContextProps>({
  currentPage: PageEnum.NODE_INFO,
  isDrawerOpen: false,
  isMobile: false,
  setCurrentPage: (value: React.SetStateAction<PageEnum>) => {},
  drawerHeight: 0,
  drawerWidth: 0,
  toggleIsDrawerOpen: () => {},
  updateDrawerAncestor: (ancestor: Ancestor, node?: NodeObject) => {},
});

export default DrawerContext;