import React from 'react';

import { Ancestor } from '../types.d';

interface CompoundContextProps {
  drawerChildren: Ancestor[];
  drawerParents: Ancestor[];
  updateAndMoveToDrawerAncestor: (ancestorId: number) => Promise<unknown>;
}

const CompoundContext = React.createContext<CompoundContextProps>({
  drawerChildren: [],
  drawerParents: [],
  updateAndMoveToDrawerAncestor: (ancestorId: number) => new Promise(() => {}),
});

export default CompoundContext;