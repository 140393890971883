/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import { LinkObject, NodeObject } from '../types.d';
import {
  Button,
  DownArrowIcon,
  KeyboardKey,
  MOBILE_BREAKPOINT,
  QuestionMarkIcon,
  UpArrowIcon,
} from '../styled';
import theme from '../theme';

import CompoundContext from '../context/CompoundContext';
import DataContext from '../context/DataContext';
import DrawerContext from '../context/DrawerContext';

const ForceGraph3D = React.lazy(() => import('react-force-graph-3d'));

const Graph = () => {
  const [isTooltipVisible, setIsTooltipVisible] = React.useState(false);

  const { drawerParents } = React.useContext(CompoundContext);
  const {
    getAncestorById,
    graphData,
    refetchData,
  } = React.useContext(DataContext);
  const {
    graphRef,
    isDrawerOpen,
    drawerAncestor,
    drawerHeight,
    drawerWidth,
    toggleIsDrawerOpen,
    updateDrawerAncestor,
  } = React.useContext(DrawerContext);

  const onNodeClick = React.useCallback(
    (node: NodeObject, event: MouseEvent) => {
      const ancestor = getAncestorById(node.id);
      if (ancestor) {
        if (!isDrawerOpen || ancestor === drawerAncestor) {
          toggleIsDrawerOpen();
        }
        if (ancestor !== drawerAncestor) {
          updateDrawerAncestor(ancestor, node);
        }
      }
    }, [
      getAncestorById,
      isDrawerOpen,
      drawerAncestor,
      toggleIsDrawerOpen,
      updateDrawerAncestor,
  ]);

  const mother = drawerParents.find((p) => p.gender === 'female');
  const father = drawerParents.find((p) => p.gender === 'male');

  useHotkeys('ctrl+shift+r', () => refetchData());

  return (
    <div
      css={css`
        position: relative;
        ${theme.transition.shortEaseOut()}

        canvas,
        .scene-container {
          ${theme.transition.shortEaseOut()}
        }

        .scene-nav-info {
          color: ${theme.colors.white};
        }
      `}
    >
      <React.Suspense fallback={<div></div>}>
        <ForceGraph3D
          backgroundColor={theme.colors.offBlack}
          graphData={graphData}
          height={window.innerHeight - drawerHeight - 20}
          linkColor={(l: LinkObject) => {
            if (drawerAncestor) {
              if ((l.source as NodeObject).id === drawerAncestor?.id && (l.target as NodeObject).id === mother?.id) {
                return theme.colors.orange.base;
              } else if ((l.source as NodeObject).id === drawerAncestor?.id && (l.target as NodeObject).id === father?.id) {
                return theme.colors.blue.base;
              } else if ((l.target as NodeObject).id === drawerAncestor?.id) {
                return theme.colors.green.base;
              } 
            }
            return theme.colors.gray.light;
          }}
          linkDirectionalParticles={1}
          linkDirectionalParticleSpeed={(d: { value: number }) => d.value * 0.02}
          linkDirectionalParticleWidth={2}
          linkWidth={(l: LinkObject) => {
            if (
              drawerAncestor &&
              (
                (l.source as NodeObject).id === drawerAncestor?.id
                || (l.target as NodeObject).id === drawerAncestor?.id
              )
            ) {
              return 3;
            }
            return 1;
          }}
          nodeColor={(n: NodeObject) => (
            n?.id === drawerAncestor?.id
              ? theme.colors.green.light
              : (
                getAncestorById(n?.id)?.gender === 'female'
                  ? theme.colors.orange.base
                  : theme.colors.blue.base
              )
          )}
          nodeLabel={(n: NodeObject) => (
            graphData.nodes.find((m) => m.id === n.id)?.label || 'Unknown'
          )}
          nodeVal={(n: NodeObject) => (
            getAncestorById(n?.id)?.tags?.includes('notable') ? 8 : 1
          )}
          onNodeClick={onNodeClick}
          ref={graphRef}
          width={window.innerWidth - drawerWidth - 20}
        />
      </React.Suspense>
      {
        isTooltipVisible && (
          <div css={css`
            position: absolute;
            bottom: calc(${theme.spacing.x8} + ${theme.spacing.base});
            right: ${theme.spacing.x3};
          `}>
            <div css={css`
              background-color: ${theme.colors.black};
              border: 1px solid ${theme.colors.white};
              border-radius: ${theme.spacing.base};
              max-width: 400px;
              padding: ${theme.spacing.x2};
            `}>
              Shortcuts:<br/>
              <KeyboardKey>Space</KeyboardKey> : Toggle Drawer<br/>
              <KeyboardKey>O</KeyboardKey> : Open Selected Ancestor Link in New Tab<br/>
              <KeyboardKey>1 or Shift+M</KeyboardKey> : Select Mother of Current Ancestor<br/>
              <KeyboardKey>2 or Shift+F</KeyboardKey> : Select Father of Current Ancestor<br/>
              <KeyboardKey>Shift+#</KeyboardKey> : Select #th Child of Current Ancestor<br/>
              <KeyboardKey>Ctrl+Space</KeyboardKey> : Toggle Search Panel<br/>
              <KeyboardKey>Ctrl+Shift+C</KeyboardKey> : Toggle Connection Config Panel<br/>
              <KeyboardKey>Ctrl+Shift+R</KeyboardKey> : Reload Graph<br/>
            </div>
          </div>
        )
      }
      <div css={css`
        position: absolute;
        bottom: ${theme.spacing.base};
        right: ${theme.spacing.base};

        @media(max-width: ${MOBILE_BREAKPOINT - 1}px) {
          right: ${theme.spacing.x3};
        }
      `}>
        <div css={css`
          @media(min-width: ${MOBILE_BREAKPOINT}px) {
            display: none;
          }
        `}>
          <Button
            onClick={() => toggleIsDrawerOpen()}
          >
            {
              isDrawerOpen ? <DownArrowIcon color={theme.colors.white} /> : <UpArrowIcon color={theme.colors.white} />
            }
          </Button>
        </div>
        <div css={css`
          @media(max-width: ${MOBILE_BREAKPOINT - 1}px) {
            display: none;
          }
        `}>
          <Button
            onClick={() => setIsTooltipVisible((v) => !v)}
          >
            <QuestionMarkIcon color={theme.colors.white} />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Graph;