/** @jsxImportSource @emotion/react */
// import { css } from '@emotion/react';
// import React from 'react';

const colors = {
  black: '#111111',
  blue: {
    base: '#4169e1',
    // dark: '',
    // light: '',
  },
  gray: {
    base: '#989898',
    dark: '#656565',
    light: '#d3d3d3',
  },
  green: {
    base: '#008000',
    // dark: '',
    light: '#7cfc00',
  },
  offBlack: '#1E1E1E',
  orange: {
    base: '#ff4500',
    // dark: '',
    // light: '',
  },
  // red: {
  //   base: '',
  //   dark: '',
  //   light: '',
  // },
  white: '#fbfbfb',
};

const BASE_SPACING = 4;

const spacing = {
  base: `${BASE_SPACING}px`,
  x2: `${BASE_SPACING * 2}px`,
  x3: `${BASE_SPACING * 3}px`,
  x4: `${BASE_SPACING * 4}px`,
  x5: `${BASE_SPACING * 5}px`,
  x6: `${BASE_SPACING * 6}px`,
  x8: `${BASE_SPACING * 8}px`,
  x12: `${BASE_SPACING * 12}px`,
};

const transition = {
  longEase: (attr = 'all') => `transition: ${attr} 1.5s ease;`,
  mediumEase: (attr = 'all') => `transition: ${attr} 1s ease;`,
  shortEase: (attr = 'all') => `transition: ${attr} 0.5s ease;`,
  veryShortEase: (attr = 'all') => `transition: ${attr} 0.25s ease;`,
  longEaseIn: (attr = 'all') => `transition: ${attr} 1.5s ease-in;`,
  mediumEaseIn: (attr = 'all') => `transition: ${attr} 1s ease-in;`,
  shortEaseIn: (attr = 'all') => `transition: ${attr} 0.5s ease-in;`,
  veryShortEaseIn: (attr = 'all') => `transition: ${attr} 0.25s ease-in;`,
  longEaseInOut: (attr = 'all') => `transition: ${attr} 1.5s ease-in-out;`,
  mediumEaseInOut: (attr = 'all') => `transition: ${attr} 1s ease-in-out;`,
  shortEaseInOut: (attr = 'all') => `transition: ${attr} 0.5s ease-in-out;`,
  veryShortEaseInOut: (attr = 'all') => `transition: ${attr} 0.25s ease-in-out;`,
  longEaseOut: (attr = 'all') => `transition: ${attr} 1.5s ease-out;`,
  mediumEaseOut: (attr = 'all') => `transition: ${attr} 1s ease-out;`,
  shortEaseOut: (attr = 'all') => `transition: ${attr} 0.5s ease-out;`,
  veryShortEaseOut: (attr = 'all') => `transition: ${attr} 0.25s ease-out;`,
  longLinear: (attr = 'all') => `transition: ${attr} 1.5s linear;`,
  mediumLinear: (attr = 'all') => `transition: ${attr} 1s linear;`,
  shortLinear: (attr = 'all') => `transition: ${attr} 0.5s linear;`,
  veryShortLinear: (attr = 'all') => `transition: ${attr} 0.25s linear;`,
};

const typeStyles = {};

const theme = {
  colors,
  spacing,
  transition,
  typeStyles,
};

export default theme;