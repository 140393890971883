/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import {
  DEFAULT_NEW_ANCESTOR,
  InputType,
  NewAncestor,
} from '../types.d';
import theme from '../theme';
import { getDisplayText } from '../utils';

import Input from './Input';

interface PropertiesPickerProps {
  handleChange: (newAncestor: NewAncestor) => void;
  newAncestor: NewAncestor;
};

const PropertiesPicker: React.FC<PropertiesPickerProps> = ({
  handleChange,
  newAncestor,
}) => {

  return (
    <div>
      {
        Object.keys(DEFAULT_NEW_ANCESTOR).map((propKey) => {
          const value = newAncestor[propKey as keyof NewAncestor] ?? '';
          let inputType;
          switch (propKey) {
            case 'notes':
              inputType = 'longtext';
              break;
            case 'gender':
              inputType = 'select';
              break;
            default:
              inputType = typeof value
          }

          return (
            <div 
              css={css`
                display: flex;
                flex-direction: column;
                ${propKey !== 'notes' && `max-width: 300px;`}
                margin: auto;
                padding: calc(${theme.spacing.base} / 2);
                padding-bottom: ${theme.spacing.x3};
                text-align: left;
              `}
              key={propKey}
            >
              <label
                css={css`
                  padding-bottom: ${theme.spacing.base};
                `}
                htmlFor={propKey}
              >
                {getDisplayText(propKey)}
              </label>
              <Input
                handleChange={(val) => handleChange({
                  ...newAncestor,
                  [propKey]: val,
                })}
                name={propKey}
                type={inputType as InputType}
                value={value ?? DEFAULT_NEW_ANCESTOR[propKey as keyof NewAncestor]}
                {
                  ...(propKey === 'gender' && (
                    {...{options: ['male', 'female']}}
                  ))
                }
              />
            </div>
          );
        })
      }
    </div>
  );
};

export default PropertiesPicker;